// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/assets/icons/ScheduleIcon.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/assets/icons/ScheduleIcon.tsx");
  import.meta.hot.lastModified = "1689253194134.0527";
}
// REMIX HMR END

import { CalendarDaysIcon } from "@heroicons/react/24/solid";
import { cn } from "~/utils/cn";
export function ScheduleIcon({
  className
}) {
  return <CalendarDaysIcon className={cn("text-sky-500", className)} />;
}
_c = ScheduleIcon;
var _c;
$RefreshReg$(_c, "ScheduleIcon");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;